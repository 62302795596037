import { mdiChartBar, mdiFileOutline, mdiHomeOutline, mdiTextBoxSearchOutline, mdiFileChartOutline, mdiChartBoxOutline, mdiTextBoxPlusOutline } from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },
  {
    title: 'Create Reports',
    icon: mdiTextBoxSearchOutline,
    children: [
      {
        title: 'Serp Report',
        to: 'serp-analysiss-new',
        icon: mdiTextBoxPlusOutline,
      },
    ],
  },
  {
    title: 'Reports',
    icon: mdiChartBar,
    children: [
      {
        title: 'SERP Analysis',
        to: 'serp-analysis-list',
        icon: mdiChartBoxOutline,
      },
    ],
  },
]
