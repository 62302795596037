import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';
import Vue from 'vue';
import Vuex from 'vuex';
import app from './app/app';
import auth from './modules/auth';
import serp_analysis from './modules/serp_analysis'
import semantic_analysis from './modules/semantic_analysis';
import keyword_research from './modules/keyword_research';
import full_reports from './modules/full_reports';
import settings from './modules/settings';
import snackbar from './modules/snackbar';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    snackbar,
    serp_analysis,
    semantic_analysis,
    keyword_research,
    full_reports,
    settings,
  },
})
