import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading: state => state.loading,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    history({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/full-report/history?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    reportData({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/full-report/report/data?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    createReport({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/full-report/create`, data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    createMulti({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/full-report/multi/create`, data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getStatus({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/full-report/status?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getFullReport({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/full-report/await?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getLastReports({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/full-report/lastReports`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getCount({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/full-report/count`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
  },
}
