import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading: state => state.loading,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    // async getSemanticAnalysisHistory({commit}){
    //     const response = await axios.get('/semantic-analysis/history');
    //     return response;
    // },
    // async getSemanticAnalysisReport({commit}, queryString){
    //     const response = await axios.get(`/semantic-analysis/report?${queryString}`);
    //     return response;
    // },

    getSemanticAnalysisHistory({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/semantic-analysis/history?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getSemanticAnalysisHistoryAll({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/semantic-analysis/lastReports`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getSemanticAnalysisCount({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/semantic-analysis/history/count`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getSemanticAnalysisReport({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/semantic-analysis/report?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getSemanticAnalysisReportData({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/semantic-analysis/report-data?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getSemanticAnalysisReportReview({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/semantic-analysis/await?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    createReport({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/semantic-analysis/create`, data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    createMulti({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/semantic-analysis/multi/create`, data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    delete({ commit }, report_id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .delete(`/semantic-analysis/delete/${report_id}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getStatus({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/semantic-analysis/status?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getAwaitData({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/semantic-analysis/await/data?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    getStatusBuild({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/semantic-analysis/build/status?${queryString}`)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
    buildReport({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .post(`/semantic-analysis/build`, data)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOADING', false))
      })
    },
  },
}
