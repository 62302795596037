import axios from '@/plugins/axios'

export default {
  namespaced: true,
  state: {
    authenticated: false,
    isLogged: false,
    user: null,
    loggingIn: false,
    accessToken: null,
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SET_LOGGING_IN(state, value) {
      state.loggingIn = value
    },
    SET_USER(state, value) {
      state.user = value
    },
    SET_ACCESS_TOKEN(state, value) {
      localStorage.setItem('accessToken', value)
      state.accessToken = value
      state.isLogged = true
    },
    setTokenStorage(state, data) {
      localStorage.setItem('accessToken', data)
      state.isLogged = true
    },
  },
  getters: {
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    },
    hasUserRole(state) {
      return role => {
        if (!role) return true

        let qRoles

        if (typeof role === 'string') qRoles = [role]
        else qRoles = role

        return state.user?.roles.filter(r => qRoles.includes(r.name)).length > 0
      }
    },
    hasUserPermission(state) {
      return permission => {
        if (!permission) return true

        let ret = false
        state.user.roles.every(role => {
          ret ||= role.permissions.findIndex(p => p.name === permission) !== -1

          return !ret
        })

        return ret
      }
    },
  },
  actions: {
    async getToken({ commit }) {
      const token = localStorage.getItem('accessToken')
      if (token) {
        commit('SET_ACCESS_TOKEN', token)
      }
    },
    async autoLogin({ commit }) {
      const token = localStorage.getItem('accessToken')
      if (token) {
        commit('SET_ACCESS_TOKEN', token)
      }
    },
    async login({ commit }, formData) {
      return new Promise((resolve, reject) => {
        commit('SET_LOGGING_IN', true)
        axios
          .post('/auth/login', formData)
          .then(response => {
            if (response && response.data) {
              const { accessToken, user } = response.data
              localStorage.setItem('userData', JSON.stringify(user))
              commit('SET_ACCESS_TOKEN', accessToken)
              resolve(response)
            }
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOGGING_IN', false))
      })
    },
    async register({ commit }, formData) {
      return new Promise((resolve, reject) => {
        commit('SET_LOGGING_IN', true)
        axios
          .post('/auth/register', formData)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_LOGGING_IN', false))
      })
    },
    async getUser({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('user')
          .then(resp => {
            const { data } = resp
            commit('SET_USER', data)
            commit('SET_AUTHENTICATED', true)
            resolve(resp)
          })
          .catch(err => {
            commit('SET_USER', {})
            commit('SET_AUTHENTICATED', false)
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/logout')
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('SET_USER', {})
            commit('SET_AUTHENTICATED', false)
          })
      })
    },
    getInvitation({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/user-invitation', formData)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    forgotPassword(dispatchable, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/forgot-password', formData)
          .then(response => {
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    resetPassword(dispatchable, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/reset-password', formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
  },
}
