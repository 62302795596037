import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from './auth';
import pages from './pages';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      layout: 'content',
      public: false,
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  ...auth,
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '**',
    redirect: 'error-404',
  },
]

async function afterEach (to, from, next) {
  await router.app.$nextTick()
}

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
const beforeEach = async (to, from, next) => {

  const token = localStorage.getItem('accessToken');

  // console.log(store.state.auth.user)

  // if(token){
  //   if (to.matched.some(record => record.meta.public)) {
  //     next();
  //   }
  //   else if (store.state.user && store.state.user.roles == 'Super Admin') {
  //     if (to.matched.some(record => record.meta.administrador)) {
  //       next();
  //     }
  //   }
  //   else if (store.state.user && store.state.user.roles == 'Client Admin') {
  //     if (to.matched.some(record => record.meta.administrador)) {
  //       next();
  //     }
  //   }
  //   else if (store.state.user && store.state.user.roles == 'Client Full') {
  //     if (to.matched.some(record => record.meta.administrador)) {
  //       next();
  //     }
  //   }
  //   else if (store.state.user && store.state.user.roles == 'Client Semantic Analysis') {
  //     if (to.matched.some(record => record.meta.administrador)) {
  //       next();
  //     }
  //   }
  //   else if (store.state.user && store.state.user.roles == 'Client Serp analysis') {
  //     if (to.matched.some(record => record.meta.administrador)) {
  //       next();
  //     }
  //   }
  //   else if (store.state.user && store.state.user.roles == 'Client Keyword Research') {
  //     if (to.matched.some(record => record.meta.administrador)) {
  //       next();
  //     }
  //   }
  //   else if (store.state.user && store.state.user.roles == 'Reports') {
  //     if (to.matched.some(record => record.meta.administrador)) {
  //       next();
  //     }
  //   }
  //   else if (store.state.user && store.state.user.roles == 'Guest') {
  //     if (to.matched.some(record => record.meta.administrador)) {
  //       next();
  //     }
  //   }
  //   else {
  //     next({ name: 'auth-login' });
  //   }
  // }
  // else {
  //   next({ name: 'auth-login' });
  // }

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })

  //   // return next({ name: 'misc-not-authorized' })
  // }

  return next()
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(beforeEach)
router.afterEach(afterEach);

export default router
