const pages = [
  {
    path: '/pages/authentication/login-v1',
    name: 'auth-login-v1',
    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/authentication/login-v2',
    name: 'auth-login-v2',
    component: () => import('@/views/pages/authentication/Login-v2.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/authentication/register-v1',
    name: 'auth-register-v1',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/authentication/register-v2',
    name: 'auth-register-v2',
    component: () => import('@/views/pages/authentication/Register-v2.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/authentication/forgot-password-v1',
    name: 'auth-forgot-password-v1',
    component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/authentication/forgot-password-v2',
    name: 'auth-forgot-password-v2',
    component: () => import('@/views/pages/authentication/ForgotPassword-v2.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/authentication/reset-password-v1',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/authentication/reset-password-v2',
    name: 'auth-reset-password-v2',
    component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },



  /* ------------------------- SERP Analysis --------------------- */
  {
    path: '/serp-analysis/new',
    name: 'serp-analysiss-new',
    component: () => import('@/views/pages/serp-analysis/NewReport.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
    },
  },
  {
    path: '/serp-analysis/list',
    name: 'serp-analysis-list',
    component: () => import('@/views/pages/serp-analysis/ReportList.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
    },
  },
  {
    path: '/serp-analysis/report/:report_id',
    name: 'serp-analysis-report',
    component: () => import('@/views/pages/serp-analysis/Report.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
    },
  },
  {
    path: '/serp-analysis/await/:report_id',
    name: 'serp-analysis-await',
    component: () => import('@/views/pages/serp-analysis/AwaitResults.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
    },
  },


  /*----------------------------- Profile ------------------------------*/
  // {
  //   path: '/profile/view/:id',
  //   name: 'profile',
  //   component: () => import('@/views/pages/profile/user-view/UserView.vue'),
  //   meta: {
  //     layout: 'content',
  //     super_admin: true,
  //     client_admin: true,
  //     client_full: true,
  //     semantic_analysis: true,
  //     serp_analysis: true,
  //     keyword_resources: true,
  //     reports: true,
  //   },
  // },
  {
    path: '/profile/view/:id',
    name: 'profile',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/pages/customize/Theme.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/pages/users/List.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
  {
    path: '/users/edit',
    name: 'usersEdit',
    component: () => import('@/views/pages/users/Edit.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
  {
    path: '/users/create',
    name: 'createUser',
    component: () => import('@/views/pages/users/Create.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
  {
    path: '/role',
    name: 'role',
    component: () => import('@/views/pages/roles/List.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
  {
    path: '/role/edit',
    name: 'roleEdit',
    component: () => import('@/views/pages/roles/Edit.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
  {
    path: '/role/create',
    name: 'createRole',
    component: () => import('@/views/pages/roles/Create.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
  {
    path: '/client',
    name: 'client',
    component: () => import('@/views/pages/client/List.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
  {
    path: '/client/edit',
    name: 'clientEdit',
    component: () => import('@/views/pages/client/Edit.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
  {
    path: '/client/create',
    name: 'createClient',
    component: () => import('@/views/pages/client/Create.vue'),
    meta: {
      layout: 'content',
      super_admin: true,
      client_admin: true,
      client_full: true,
      semantic_analysis: true,
      serp_analysis: true,
      keyword_resources: true,
      reports: true,
      guest: true,
    },
  },
]

export default pages
