import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import VueAlertify from 'vue-alertify'
import VueApexCharts from 'vue-apexcharts'
import VueColumnsResizableVuetify from 'vue-columns-resizable-vuetify'
import excel from 'vue-excel-export'
//import JsonCSV from 'vue-json-csv'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

var numeral = require("numeral")

Vue.config.productionTip = false
Vue.use(VueAlertify)
Vue.use(excel)
Vue.use(VueColumnsResizableVuetify)
Vue.use(VueApexCharts)
//Vue.component('downloadCsv', JsonCSV)
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0"); 
});
Vue.component('apexchart', VueApexCharts)
new Vue({
  data: {
    imgNav: "require('@/assets/images/avatars/1.png')",
  },
  router,
  store,
  // i18n,
  vuetify,
  
  render: h => h(App),
}).$mount('#app')


