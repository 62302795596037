export const auth = [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      public: true,
    },
  },
];

export default auth;
