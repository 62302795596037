<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>

    <v-snackbar v-model="snackbar.status" :color="snackbar.color" :timeout="timeout" :top="false" :bottom="true">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onBeforeMount, meh } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import { mapActions, mapGetters } from 'vuex'
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  data() {
    return {
      fab: false,
      timeout: 3000,
      snackbar: {
        text: this.$store.state.snackbar.text,
        status: this.$store.state.snackbar.status,
        color: this.$store.state.snackbar.color,
      },
    }
  },
  watch: {
    '$store.state.snackbar.text'(val) {
      this.snackbar.text = val
    },
    '$store.state.snackbar.status'(val) {
      if (val) {
        this.snackbar.status = true
      }
    },
    '$store.state.snackbar.color'(val) {
      this.snackbar.color = val
    },
    'snackbar.status'(val) {
      if (!val) {
        this.setSnackbar({ status: false })
      }
    },
  },
  computed: {
    isAuth() {
      return this.$store.state.auth
    },
  },
  created() {
    this.autoLogin().then(() => {
      this.getUser()
    })
    if (!this.$store.state.auth.isLogged) {
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    ...mapActions({
      autoLogin: 'auth/autoLogin',
      getUser: 'auth/getUser',
      setSnackbar: 'snackbar/set',
    }),
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()
    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
